<template>
  <div class="DashboardOnboardingIntroView">
    <div class="topSection">
      <LifekeyPopupRive 
        v-if="activeSlideIndex === 4" 
        :show-belt="true" 
        :lifekey-count="2" 
        :category-name="'knowledge'" 
        :onboarding="true" 
        class="riveAnimation" />
      
      <div
        v-if="activeSlideIndex < 4"
        class="Slide__image"
        :data-name="`slide${activeSlideIndex + 1}`"
        :style="{ backgroundImage: `url(${getSlideImage(activeSlideIndex + 1)})` }" />

    </div>
    
    <SimpleSlides
      :button-label="$t(`getStarted`)"
      on-dark-background
      @update:index="activeSlideIndex = $event"
      @done="gotoNext()">
      <template slot="slides">
        <div
          v-for="i of 5"
          :key="i"
          class="Slide">

          <div class="Slide__title">
            {{ $t(`slide${i}.title`) }}
          </div>

          <div class="Slide__text">
            {{ $t(`slide${i}.text`) }}
          </div>
        </div>
      </template>
    </SimpleSlides>

  </div>
</template>

<translations>
  getStarted: 'Get Started'
  getStarted_no: 'Kom i gang'

  slide1:
    title: 'Stay Ahead of Change'
    title_no: 'Ligg et hode foran'
    text: 'The world is changing fast. Memolife uses new insight about the brain to let you stay ahead of change with the most important skills for today and tomorrow.'
    text_no: 'Samfunnet endrer seg hurtig. Memolife bruker ny innsikt om hjernen til å hjelpe deg ligget et hode foran disse endringene med de viktigste ferdighetene for i dag og i morgen.'
    image: './slide1.png'
  slide2:
    title: 'Level Up Your Brain'
    title_no: 'Spill hjernen topptrent'
    text: 'Learn fast with fun hacks, remember forever with smart intervals and ace new habits with real life scenarios.'
    text_no: 'Lær hurtig med morsomme hacks, husk for alltid med smarte intervaller og skap nye vaner med ekte scenarioer.'
    image: './slide2.png'
  slide3:
    title: 'Do Your First Brain Date'
    title_no: 'Gå på første hjernedate'
    text: 'Level up your brain with superfast learning of theory, practice and videos - a 3 minute brain date is all you need.'
    text_no: 'Spill hjernen i form med lynrask læring av teori, praksis og video – en hjernedate på 3 minutter er alt du trenger.'
    image: './slide3.png'
  slide4:
    title: 'Get Your First Life Key'
    title_no: 'Få din første livsnøkkel'
    text: 'Enjoy your progress with life keys - the mastery badges that appear after finishing brain dates of theory and pratice.'
    text_no: 'Nyt fremgangen med livsnøkler - pokalene for mestring, som dukker opp etter noen hjernedates med teori og praksis.'
    image: './slide4.png'
  slide5:
    title: 'Earn Your First Belt'
    title_no: 'Motta ditt første belte'
    text: 'The white belt makes you ready to embark on your first learning journeys towards the black belt in life - at work and at home.'
    text_no: 'Det hvite beltet gjør deg klar til å sette ut på de første læringreisene mot det svarte beltet i livet – på jobb og hjemmebane.'
    image: './slide5.svg'
</translations>

<script>
import SimpleSlides from '@/components/SimpleSlides';
import LifekeyPopupRive from '../../components/LifekeyPopup/LifekeyPopupRive';

const images = require.context('./', true, /\.(png|jpg|svg)$/);

export default {
    components: { SimpleSlides, LifekeyPopupRive },
    data() {
        return {
            activeSlideIndex: 0
        };
    },
    methods: {
        imageUrl(path) {
            return images(`${path}`);
        },
        getSlideImage(slideNumber) {
            return this.imageUrl(this.$t(`slide${slideNumber}.image`));
        },
        gotoNext() {
            this.$store.dispatch('moduleApp/markDashboardOnboardingAsShown');
            this.$router.push({
                name: 'DashboardHomeView'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardOnboardingIntroView {
    background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
}
.SimpleSlides {
    width: 80%;

    align-self: center;
    flex: 1;
    padding: 0;

    display: flex;
    flex-direction: column;

    /deep/ .slides {
        flex: 1;
    }
}

.topSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.riveAnimation {
    flex: 1;
    height: 22em;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Slide__image {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &[data-name='slide1'] {
        background-size: auto 100%; // 140%;
        background-position: center top;
    }
    &[data-name='slide2'] {
        width: 30em;
        height: 22em;
        background-size: contain;
    }
    &[data-name='slide3'] {
        width: 22em;
        height: 18.5em;
        background-size: 80%;
    }
    &[data-name='slide4'] {
        width: 20em;
        height: 16em;
        margin-bottom: 2em;
    }
    &[data-name='slide5'] {
        width: 22em;
        height: 22em;
        margin-bottom: 1em;
    }
}

.Slide {
    flex: 1;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.Slide__title {
    color: white;
    font-size: 180%;
    font-weight: 600;
    text-align: center;
}
.Slide__text {
    color: rgba(white, 0.5);
    font-size: 110%;
    line-height: 1.5em;
    text-align: center;
    max-width: 20em;
    margin-top: 1em;
}

@include media('>=mobile') {
    .Slide {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Slide__title {
        max-width: none;
    }
}
@include media('<mobile') {
    .topSection {
        flex: 1.5;
    }
    .Slide__title {
        text-align: center;
    }
    .Slide__text {
        text-align: center;
    }

    .SimpleSlides {
        width: calc(100% - 2em);
    }

    .Slide__image {

        &[data-name='slide1'] {
        }
        &[data-name="slide2"] {
          margin-bottom: 2em;
        }
        &[data-name="slide3"] {
          margin-bottom: 2em;
        }
        &[data-name="slide4"] {
          margin-bottom: 4em;
        }
        &[data-name="slide5"] {
          margin-bottom: 2em;
        }
    }
}
</style>
